/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
/* Disable horizontal scrolling */
body {
    overflow-x: hidden;
}

//body {
//    overflow-y: hidden;
//}
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.dataTables_empty {
    display: none;
}
